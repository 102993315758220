import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"

// import MenuButton from "./MenuButton"
import "./Menu.css"

const Menu = ({ mainNav, headerLink, currentPage }) => {
  return (
    <nav className="flex items-center text-xs tracking-tightest justify-end -ml-gs">
      {mainNav &&
        mainNav.map((item, index) => {
          if (item.type === "" || item.type === null) {
            if (item.url.startsWith("#")) {
              if (currentPage === "/") {
                return (
                  <div key={index} className="pl-gs">
                    <ScrollLink
                      activeClass="Link--active"
                      spy={true}
                      smooth={true}
                      offset={-83}
                      duration={500}
                      to={item.url.replace(/#/g, "")}
                      className="Link pt-1 ScrollLink"
                    >
                      {item.title}
                    </ScrollLink>
                  </div>
                )
              } else {
                return (
                  <div key={index} className="pl-gs">
                    <a href={`/${item.url}`} className="Link pt-1">
                      {item.title}
                    </a>
                  </div>
                )
              }
            } else {
              return (
                <div key={index} className="xl:pl-gs">
                  <a
                    className="Link pt-1"
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </div>
              )
            }
          } else {
            return (
              <div key={index} className="pl-gs">
                <Link
                  to={`/${
                    item.element.slug === "home" ? "" : item.element.slug
                  }`}
                  className="Link pt-1"
                  activeClassName="border-header-text"
                >
                  {item.title}
                </Link>
              </div>
            )
          }
        })}
      {/* {headerLink.element && (
        <div className="ml-auto lg:ml-0 xl:pl-gs flex-shrink-0">
          <MenuButton headerLink={headerLink} />
        </div>
      )} */}
    </nav>
  )
}

export default Menu
