import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../Seo"
import Header from "../header/Header"
import "./Layout.css"
// import GridHelper from "../grid-helper/GridHelper"

const Layout = ({ children, title, description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const currentPage =
    typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <div className="pt-shh bg-header-bg relative overflow-hidden">
      <Seo
        title={title}
        description={description}
        theme={["#274872", "##DBF5B0"]}
      />
      {/*<GridHelper />*/}
      <Header
        siteTitle={data.site.siteMetadata.title}
        currentPage={currentPage}
      />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
