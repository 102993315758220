import * as React from "react"

const LogoMark = ({ siteTitle }) => {
  return (
    <svg
      className="w-full h-full stroke-current transition-colors"
      viewBox="0 0 24 29.8"
    >
      {siteTitle && <title>{siteTitle}</title>}
      <path
        fill="none"
        strokeWidth="0.65"
        d="M12,29.5c-2.2,0-4.2-0.5-5.8-1.5C4.4,27,3,25.4,2,23.5c-1.1-2.3-1.7-5.1-1.7-8.6c0-3.5,0.5-6.3,1.7-8.6
        c1-2,2.5-3.6,4.3-4.6c1.7-1,3.6-1.4,5.7-1.4c2.2,0,4.2,0.5,5.9,1.6c1.7,1,3.1,2.5,4.1,4.5c1.1,2.3,1.7,5.1,1.7,8.6
        c0,3.5-0.5,6.3-1.7,8.6c-1,1.9-2.4,3.5-4.2,4.5C16.1,29,14.2,29.5,12,29.5z"
      />
      <path
        fill="none"
        strokeWidth="0.65"
        d="M6.2,14.9c0,1,0,1.9,0.2,2.9c0.1,0.9,0.4,1.9,0.7,2.7c0.3,0.8,0.8,1.5,1.4,2c0.8,0.7,2,1.2,3.5,1.2
        c1.4,0,2.4-0.4,3.3-1c0.6-0.5,1.1-1,1.4-1.7c0.5-0.9,0.8-2,1-3.1c0.1-1,0.2-1.9,0.2-2.9c0-1,0-2-0.2-2.9c-0.1-0.8-0.3-1.5-0.5-2.3
        c-0.4-1.1-1-2-1.9-2.6c-0.8-0.6-1.9-1-3.2-1c-1.2,0-2.2,0.3-2.9,0.8c-1,0.6-1.6,1.5-2,2.5c-0.3,0.8-0.5,1.6-0.7,2.5
        C6.2,13,6.2,14,6.2,14.9z"
      />
      <path fill="none" strokeWidth="0.65" d="M12,12v5.8" />
    </svg>
  )
}

export default LogoMark
