import * as React from "react"

const Logo = ({ title }) => {
  return (
    <svg
      className="w-full h-full fill-current transition-colors"
      viewBox="0 0 139.9 29"
    >
      <title>{title}</title>
      <path d="M137 0c1.7 0 2.9 1.3 2.9 2.9s-1.2 2.9-2.9 2.9-2.9-1.3-2.9-2.9S135.3 0 137 0zm0 5.4c1.5 0 2.4-1.1 2.4-2.4 0-1.4-1-2.4-2.4-2.4-1.5 0-2.4 1.1-2.4 2.4 0 1.3.9 2.4 2.4 2.4zm1.2-1.1h-.3l-.1-.8c0-.3-.2-.4-.5-.4h-.9v1.1h-.4V1.5h1.2c.6 0 .9.3.9.7 0 .4-.3.7-.7.7.4 0 .5.2.6.5l.2.9zm-1-1.4c.4 0 .6-.2.6-.5s-.2-.5-.6-.5h-.8V3h.8zm-61.8 9.4c0 1.3 1.1 2.8 2.9 3.2l6 1.4c3.9.9 5.5 2.9 5.5 5.9 0 3.9-3.3 6.3-8.6 6.3-5.8 0-9.2-2.6-9.5-7.2H75c.3 2.8 2.5 4.4 6.3 4.4 3.2 0 5.3-1.3 5.3-3.4 0-1.5-.8-2.7-2.9-3.2l-6.1-1.4c-3.2-.7-5.3-2.9-5.3-5.8 0-3.8 3.2-6.2 8.4-6.2 5.5 0 8.9 2.8 8.9 7.2h-3.3c0-2.7-2.2-4.4-5.6-4.4-3.3 0-5.3 1.2-5.3 3.2zm58.7 1.6v14.7h-3.2V14.9c0-3.4-2.2-5.6-5.5-5.6-3.8 0-6.3 3.1-6.3 7.7v11.6h-3.2V7h3l.2 6h.2c.5-4 3.4-6.6 7.3-6.6 4.5-.1 7.5 3 7.5 7.5zM109 20.7c-.2.7-.4 1.4-.7 2-1.1 2.3-3.3 3.6-5.9 3.6-2.6 0-4.8-1.3-5.9-3.6-.6-1.2-1-2.7-1-4.6h16.9v-.5c0-2.6-.4-4.7-1.3-6.4-1.7-3.3-4.9-4.9-8.7-4.9-3.8 0-7 1.6-8.7 4.9-.9 1.8-1.3 3.9-1.3 6.4s.4 4.7 1.3 6.4c1.7 3.3 4.9 4.9 8.7 4.9 3.8 0 7-1.6 8.7-4.9.5-1 .9-2.1 1.1-3.4H109zm-12.5-8.2c1.1-2.3 3.3-3.6 5.9-3.6 2.6 0 4.8 1.3 5.9 3.6.5.9.8 2 .9 3.3H95.6c.1-1.2.4-2.3.9-3.3zM65.2.6v12.7H65c-.2-.9-.5-1.7-.8-2.4-1.4-3-4.1-4.6-7.4-4.6-3.3 0-6 1.6-7.4 4.6-.8 1.8-1.3 4-1.3 6.8s.4 5 1.3 6.8c1.4 3 4.1 4.6 7.4 4.6 3.3 0 6-1.6 7.4-4.6.3-.7.6-1.5.8-2.4h.2v6.6h3.2V.7h-3.2zm-1 22.1c-1.1 2.3-3.3 3.6-5.9 3.6s-4.8-1.3-5.9-3.6c-.7-1.3-1-3-1-5.1s.3-3.7 1-5.1c1.1-2.3 3.3-3.6 5.9-3.6s4.8 1.3 5.9 3.6c.7 1.3 1 3 1 5.1s-.3 3.8-1 5.1zm-22.3-2c-.2.7-.4 1.4-.7 2-1.1 2.3-3.3 3.6-5.9 3.6s-4.8-1.3-5.9-3.6c-.6-1.2-1-2.7-1-4.6h16.9v-.5c0-2.6-.4-4.7-1.3-6.4-1.7-3.3-4.9-4.9-8.7-4.9s-7 1.6-8.7 4.9c-.9 1.8-1.3 3.9-1.3 6.4s.4 4.7 1.3 6.4c1.7 3.3 4.9 4.9 8.7 4.9s7-1.6 8.7-4.9c.5-1 .9-2.1 1.1-3.4h-3.2zm-12.5-8.2c1.1-2.3 3.3-3.6 5.9-3.6 2.6 0 4.8 1.3 5.9 3.6.5.9.8 2 .9 3.3H28.5c.1-1.2.4-2.3.9-3.3zM0 25.5L20.2 2.6s-.1 0-.2-.1c-.8.5-2.1 1.1-3.7 1.1H.3v-3h23.4v3L3.4 26.5s.1 0 .2.1c.8-.5 2.1-1.1 3.7-1.1h17v3H0v-3z" />
    </svg>
  )
}

export default Logo
