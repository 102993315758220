import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
import { Link as ScrollLink } from "react-scroll"

import "./Header.css"
import Logo from "../svg/Logo"
import LogoMark from "../svg/Logomark"
import Menu from "../menu/Menu"

const Header = ({ siteTitle, currentPage }) => {
  const data = useStaticQuery(graphql`
    query {
      allCraftMainNavNode {
        nodes {
          title
          url
          type
          element {
            ... on Craft_pages_pages_Entry {
              slug
            }
            ... on Craft_blog_blog_Entry {
              slug
            }
            ... on Craft_home_home_Entry {
              slug
            }
            ... on Craft_articles_articles_Entry {
              slug
            }
          }
        }
      }
      craftGeneralGlobalSet {
        headerLink {
          text
          element {
            ... on Craft_register_register_Entry {
              slug
            }
            ... on Craft_pages_pages_Entry {
              slug
            }
          }
        }
      }
    }
  `)
  const mainNav = data.allCraftMainNavNode.nodes
  const { headerLink } = data.craftGeneralGlobalSet

  return (
    <header className="fixed top-0 inset-x-0 h-shh font-tomato bg-header-bg text-header-text z-50 transition-colors transform-gpu">
      <Fade
        triggerOnce
        duration={500}
        delay={currentPage === "/" ? 1500 : 250}
        direction={currentPage === "/" ? "down" : "undefined"}
      >
        <div className="h-shh flex items-center justify-between gap-gs px-ogs">
          <div className="sm:flex-1">
            {currentPage === "/" ? (
              <ScrollLink
                smooth={true}
                duration={500}
                to="home"
                className="Header-logo cursor-pointer"
              >
                <Logo />
              </ScrollLink>
            ) : (
              <Link to="/" className="Header-logo">
                <Logo />
              </Link>
            )}
          </div>
          <div className="hidden lg:block w-24 flex-shrink-0">
            <div className="Header-logomark">
              {currentPage === "/" ? (
                <ScrollLink
                  smooth={true}
                  duration={500}
                  to="home"
                  className="cursor-pointer"
                >
                  <LogoMark title={siteTitle} />
                </ScrollLink>
              ) : (
                <Link to="/">
                  <LogoMark title={siteTitle} />
                </Link>
              )}
            </div>
          </div>
          <div className="flex-1">
            <Menu
              currentPage={currentPage}
              mainNav={mainNav}
              headerLink={headerLink}
            />
          </div>
        </div>
      </Fade>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
